import { ErrorHandler, Injectable, Injector } from "@angular/core";

@Injectable()
export class LoadChunkErrorHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      console.error(error);
      document.location.reload();
    }
  }
}
