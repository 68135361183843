<div class="loading" [hidden]="!show_loading">
  <div class="loader">Loading...</div>
</div>

<!-- Step 1 -->
<div class="container" *ngIf="formStep == 1 && invited == false">
  <div class="row d-flex full-height">
    <div class="col-sm-5 shade-bg d-flex flex-column">
      <img src="assets/img/logo-default.svg" width="150" alt="Client Hub" />

      <div class="headline">
        <h1>Join Client Hub</h1>
        <p *ngIf="!isFree">Create your account for free.</p>
        <p *ngIf="isFree">Create your free Recategorize account.</p>
      </div>

      <div class="illustration" style="align-items: flex-start; margin-top: 243px">
        <div class="testimonial">
          <div class="testimonial-avatar-name">
            <div class="testimonial-avatar" style="background: url(assets/img/landing-page-v3/blake.png)"></div>
            <div class="testimonial-name">
              Blake Oliver<br />
              <strong>Founder &#64; Earmark App</strong>
            </div>
          </div>
          <div class="testimonial-text">
            When I ran my firm, I dreamed about having an app just like this
            that can solve these exact pain points. Bravo to Client Hub for
            actually doing it.
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 offset-sm-1">
      <div class="form">
        <!-- Email -->
        <div class="form-group">
          <label for="email">Work email</label>
          <p style="font-size: 14px; opacity: 0.8">
            Use your work email, because this is where you will get notified of
            key activity
          </p>
          <input required [(ngModel)]="ownerEmail" type="email" id="email" class="form-control"
            placeholder="Your work email address" />
          <p style="font-size: 14px; opacity: 0.8; color: red" *ngIf="lastValidatedEmail == ownerEmail && !validEmail">
            {{ emailErrorMessage }}
          </p>
        </div>

        <!-- Name -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="firstname">First name</label>
              <input required [(ngModel)]="ownerFirstName" type="text" id="firstname" class="form-control"
                placeholder="Your first name" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="lastname">Last name</label>
              <input required [(ngModel)]="ownerLastName" type="text" id="lastname" class="form-control"
                placeholder="Your last name" />
            </div>
          </div>
        </div>

        <!-- Password -->
        <div class="password-fields">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="password">Password</label>
                <div class="input-password">
                  <input [(ngModel)]="password" type="password" id="password" class="form-control"
                    placeholder="Your password" />
                  <i class="feather eye-off"></i>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="confirmpassword">Confirm Password</label>
                <div class="input-password">
                  <input [(ngModel)]="confirmpassword" type="password" id="confirmpassword" class="form-control"
                    placeholder="Confirm your password" />
                  <i class="feather eye-off"></i>
                </div>
              </div>
            </div>
          </div>
          <!-- Pass requirements -->
          <div class="password-requirements">
            <strong>Password requirements</strong>
            <div class="row">
              <div class="col-md-6">
                <div class="requirement" [ngClass]="{ 'green-text': hasCapitalLetter() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasCapitalLetter(),
                      'check-circle': hasCapitalLetter()
                    }"></i>
                  Capital letters
                </div>
                <div class="requirement" [ngClass]="{ 'green-text': hasLowerCaseLetter() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasLowerCaseLetter(),
                      'check-circle': hasLowerCaseLetter()
                    }"></i>
                  Lower-case letters
                </div>
                <div class="requirement" [ngClass]="{ 'green-text': hasSymbols() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasSymbols(),
                      'check-circle': hasSymbols()
                    }"></i>
                  Symbols
                </div>
              </div>
              <div class="col-md-6">
                <div class="requirement" [ngClass]="{ 'green-text': hasNumbers() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasNumbers(),
                      'check-circle': hasNumbers()
                    }"></i>
                  Numbers
                </div>
                <div class="requirement" [ngClass]="{ 'green-text': hasEightChars() }">
                  <i class="feather" [ngClass]="{
                      circle: !hasEightChars(),
                      'check-circle': hasEightChars()
                    }"></i>
                  At least 8 characters
                </div>
                <div class="requirement" [ngClass]="{ 'green-text': isConfirmPasswordSame() }">
                  <i class="feather" [ngClass]="{
                      circle: !isConfirmPasswordSame(),
                      'check-circle': isConfirmPasswordSame()
                    }"></i>
                  Confirm password is equal
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Button to step 2 -->
        <p style="font-size: 12px; opacity: 0.8">
          By creating an account, you agree to our
          <a routerLink="/terms-of-service" target="_blank" rel="noreferrer noopener">Terms of Service</a>
          and
          <a routerLink="/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.
        </p>
        <button class="btn btn-success btn-lg btn-block" [disabled]="validateForm()" (click)="nextStep()">
          Create Account
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Step 2 -->
<div class="container" *ngIf="formStep == 2 && invited == false">
  <div class="row d-flex full-height">
    <div class="col-sm-5 shade-bg d-flex flex-column">
      <img src="assets/img/logo-default.svg" width="150" alt="Client Hub" />

      <div class="headline">
        <h1>Your Business <br />Information</h1>
        <p>Just one more step: <br />Enter information about your business.</p>
      </div>

      <div class="illustration">
        <img style="max-width: 100%; margin-left: -30px" src="assets/img/registration/join-team-illustration.png"
          alt="Illustration of firm people" />
      </div>
    </div>
    <div class="col-sm-6 offset-sm-1">
      <div class="form">
        <div class="form-group">
          <label for="firmname">Your firm or practice name</label>
          <input required [(ngModel)]="accountingCompanyName" type="text" id="firmname" class="form-control"
            placeholder="Firm name" autofocus />
        </div>
        <div class="form-group">
          <label for="teamsize">Team size</label>
          <div class="radio-buttons">
            <label class="form-check">
              <input class="form-check-input" type="radio" [checked]="teamSize == '0'"
                [ngModelOptions]="{ standalone: true }" name="teamsize" id="teamsize1" [value]="'0'"
                [(ngModel)]="teamSize" />
              <span class="form-check-label" for="teamsize1"> 1 - 2 </span>
            </label>
            <label class="form-check">
              <input class="form-check-input" type="radio" name="teamsize" [checked]="teamSize == 1"
                [ngModelOptions]="{ standalone: true }" id="teamsize2" [value]="1" [(ngModel)]="teamSize" />
              <span class="form-check-label" for="teamsize2"> 3 - 5 </span>
            </label>
            <label class="form-check">
              <input class="form-check-input" type="radio" name="teamsize" [checked]="teamSize == 2"
                [ngModelOptions]="{ standalone: true }" id="teamsize3" [value]="2" [(ngModel)]="teamSize" />
              <span class="form-check-label" for="teamsize3"> 6 - 10 </span>
            </label>
            <label class="form-check">
              <input class="form-check-input" type="radio" name="teamsize" id="teamsize4" [checked]="teamSize == 3"
                [ngModelOptions]="{ standalone: true }" [value]="3" [(ngModel)]="teamSize" />
              <span class="form-check-label" for="teamsize4"> 11 - 25 </span>
            </label>
            <label class="form-check">
              <input class="form-check-input" type="radio" name="teamsize" [checked]="teamSize == 4"
                [ngModelOptions]="{ standalone: true }" id="teamsize5" [value]="4" [(ngModel)]="teamSize" />
              <span class="form-check-label" for="teamsize5"> 26 + </span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label for="phone">Phone number</label>
          <input [(ngModel)]="ownerPhone" type="text" id="phone" class="form-control" placeholder="Phone number" />
        </div>

        <button class="btn btn-success btn-lg btn-block" [disabled]="validateStepTwo()" (click)="continueToTrial()">
          Start Now
        </button>
      </div>
    </div>
  </div>
</div>

<!-- invite -->
<div *ngIf="invited == true">
  <div class="container text-center" style="margin-top: 32px; margin-bottom: 32px">
    <img src="assets/img/logo-default.svg" width="150" alt="Client Hub" />
  </div>

  <form [formGroup]="infoForm">
    <div class="row" *ngIf="step == 2">
      <div class="col-sm-6 offset-sm-3">
        <h3>What is your name?</h3>
        <p>
          Your name will be used to identify you in your team and client
          conversations.
        </p>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="first_name">First name</label>
              <input id="first_name" [ngClass]="
                  formUtil.displayFieldCss(
                    infoForm,
                    'first_name',
                    infoFormSubmitAttempt
                  )
                " formControlName="first_name" type="text" class="form-control" placeholder="Heather" />
              <div class="form-invalid-message" *ngIf="
                  !infoForm.get('first_name').valid &&
                  infoForm.get('first_name').dirty &&
                  infoFormSubmitAttempt
                ">
                Cannot be empty.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="last_name">Last name</label>
              <input id="last_name" [ngClass]="
                  formUtil.displayFieldCss(
                    infoForm,
                    'last_name',
                    infoFormSubmitAttempt
                  )
                " type="text" formControlName="last_name" class="form-control" placeholder="Mason" />
              <div class="form-invalid-message" *ngIf="
                  !infoForm.get('last_name').valid &&
                  infoForm.get('last_name').dirty &&
                  infoFormSubmitAttempt
                ">
                Cannot be empty.
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <h3>Set your password</h3>
            <p>
              Your password must contain capital and lower-case letters,
              symbols, numbers and at least 8 characters.
            </p>
            <div class="form-group">
              <label for="password">Password</label>
              <div class="input-password" [ngClass]="
                  formUtil.displayFieldCss(
                    infoForm,
                    'password',
                    infoFormSubmitAttempt
                  )
                ">
                <input id="password" type="password" [ngClass]="
                    formUtil.displayFieldCss(
                      infoForm,
                      'password',
                      infoFormSubmitAttempt
                    )
                  " formControlName="password" class="form-control" placeholder="Password" />
                <i class="feather eye-off"></i>
              </div>
              <div class="form-invalid-message" *ngIf="
                  !infoForm.get('password').valid &&
                  infoForm.get('password').dirty &&
                  infoFormSubmitAttempt
                ">
                Password must match the specified conditions
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button class="btn btn-lg btn-primary" [disabled]="loadingNameAndPassword"
              (click)="step_name_and_password()">
              {{
              invited && registrationService.invite_info.invited_to !== null
              ? "Create Account and Join " +
              registrationService.invite_info.invited_to.name
              : "Continue to Company Info"
              }}
              <i class="feather chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="row" *ngIf="step == 3">
    <form [formGroup]="companyForm">
      <div class="col-sm-6 offset-sm-3">
        <h3>Company info</h3>
        <div class="form-group">
          <label for="practiceName01">Practice Name</label>
          <input id="practiceName01" type="text" formControlName="company_name" class="form-control"
            placeholder="Chicago Accounting Pros" />
        </div>
        <br />
        <div class="text-center">
          <button class="btn btn-lg btn-primary" [disabled]="!companyForm.valid" (click)="openModal(terms)">
            Create Company <i class="feather chevron-right"></i>
          </button>
        </div>
      </div>
    </form>
  </div>

  <ng-template #terms>
    <div class="modal-header text-center" style="border-bottom: none">
      <i class="feather check-circle x3" style="color: #0084ff"></i>
      <h4>Accept Terms</h4>
      <p>
        By creating an account, you agree to our
        <a routerLink="/terms-of-service" target="_blank" rel="noreferrer noopener">Terms of Service</a>
        and
        <a routerLink="/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.
      </p>
    </div>
    <div class="modal-footer">
      <div class="btn btn-default pull-left" aria-label="Cancel" (click)="modalRef.hide()">
        Cancel
      </div>
      <div class="btn btn-primary pull-right" (click)="add_company()">
        I Agree
      </div>
    </div>
  </ng-template>
</div>