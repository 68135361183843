import {
  Component,
  OnInit,
  TemplateRef /*ViewContainerRef*/,
} from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { Router, NavigationEnd, RouterLink } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
// import { LoginService } from './login.service';
// import { ToastrService } from 'ngx-toastr';;

@Component({
    selector: "terms-of-service",
    templateUrl: "./terms-of-service.component.html",
    styleUrls: ["./website.component.css"],
    standalone: true,
    imports: [RouterLink],
})
export class TermsOfServiceComponent implements OnInit {
  public modalRef: BsModalRef;
  public email: String = "";
  public password: String = "";

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public modalService: BsModalService // vcr: ViewContainerRef
  ) {
    //
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  //   login() {
  //     if (this.email === '' || this.password === '') {
  //         this.toastr.error('Email/Password cannot be empty', 'Oops!');
  //         return;
  //     } else {
  //       this.loginService.login(this.email, this.password).then((res: any) => {
  //         this.localStorage.set('access_token', res.access_token);
  //         this.localStorage.set('user', res.user);
  //         this.localStorage.set('data', res.data);
  //         this.localStorage.set('company', res.company);

  //         this.router.navigate(['/dashboard/main']);
  //       }, (err) => {
  //         console.log(err);
  //         this.toastr.error(err.non_field_errors[0], 'Oops!');
  //         return;
  //       });
  //     }
  //   }
}
